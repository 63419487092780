import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/common/header';
import Footer from '../components/common/footer';
import ContactUsform from '../components/Contactus/contactus';
import StepSection from '../components/common/Steps';
import { Helmet } from 'react-helmet-async';


const TermsConditions = () => {
    return (
        <div>
            <Helmet>
                <title>Terms & Conditions - Animation Rush</title>
                <meta name="description" content="Review the Terms and Conditions of Animation Rush. Understand your rights, our policies, and guidelines for a transparent collaboration." />
                <link rel="canonical" href="https://animationrush.com/terms-conditions" />
            </Helmet>

            <Header />

            <div className="container py-5">
      <h1 className="text-center">Terms and Conditions</h1>
      <div className="mt-4">
        <h5>Thank you for choosing Animation Rush (a d/b/a of Virtual Reality Creators Corp.)!</h5>
        <p>
          These Terms of Service (“Terms” or “Agreement”) are an agreement between you (if signing as an individual or the legal entity you represent) (“you,” or “Client,” which term shall mean the user of the Animation Rush’s Services (defined below)) and Virtual Reality Creators Corp., a NJ corporation with a principal address of 41 Winthrop Rd, Edison, NJ 08817 (“Animation Rush”, “Company,” “us,” or “we,” as the context requires) that governs your rights and obligations regarding your purchase and use of the Services.
        </p>
        <p>
          Please read the entire Agreement carefully because all of the terms are important and together create a legal agreement that, once accepted by you, applies to you. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT SIGN UP AND DO NOT USE THE SERVICES. BY USING OUR SERVICES, YOU AGREE TO BE BOUND BY THESE TERMS AND THE TERMS AND CONDITIONS SET FORTH IN OUR PRIVACY. We reserve the right to change these Terms from time to time and your continued use of the Services will signify your acceptance of any adjustment to these Terms (if you do not agree to the new terms, you should discontinue using the Services). By using the Services, you represent that you are 18 years of age or older and agree to be bound by this Agreement or you are the parent or legal guardian of a minor that has an account associated with yours and you are accepting this Agreement on their behalf.
        </p>

        <h5>1. Services</h5>
        <p>
          We are very pleased that you (“Client” or “you” as the context requires) have engaged Virtual Reality Creators Corp., a NJ corporation, d/b/a Animation Rush (“Animation Rush”, “Company,” “us,” or “we,” as the context requires) to perform the services as set forth in a statement of work (or quote, as the case may be), and any subsequent written statement of work, which may be electronically accepted (each a “SOW” (also may be referred to as a “Quote”) and the work thereunder, the “Services”). This letter (the “Agreement”) is effective as of the last date of signature below (the “Effective Date”). There are no products sold or provided under this Agreement. Client understands and agrees that each Quote will be governed by this Agreement.
        </p>

        <h5>2. Orders, Term</h5>
        <p>
          Client shall purchase Services by delivering to Company a signed SOW (a sample form of SOW is attached hereto as Exhibit A). The term of each SOW shall be stated in the SOW. Each SOW hereunder shall terminate immediately upon termination of the Agreement, unless otherwise agreed by Company. If, after completion of the Services, we continue to perform services at your written request, the terms of this Agreement shall be deemed renewed for so long as such additional services are being provided.
        </p>

        <h5>3. Fee</h5>
        <p>
          Subject to the terms and conditions of this Agreement, we have agreed upon the fee arrangement as more specifically set forth on the applicable Quote. Each Quote will set forth the Services, price, and any other information reasonably necessary for such engagement. Unless otherwise agreed by the parties in writing, if services are required outside the scope of those set forth in a Quote, our time will be billed at our then current hourly rates or Company-decided fixed price. All stated prices are exclusive of any taxes, fees and duties or other amounts, including, without limitation, value added and withholding taxes levied or based upon such charges, or upon this Agreement (other than taxes based on the net income of Company). Client shall pay any taxes related to Services purchased under this Agreement or Client shall present an exemption certificate acceptable to the taxing authorities. Applicable taxes shall be billed as a separate item on the invoice, to the extent possible. ALL FEES MADE TO COMPANY BY CLIENT PURSUANT TO THE SOW IS CONSIDERED FULLY EARNED BY COMPANY AT TIME OF PAYMENT AND IS NONREFUNDABLE FOR ANY REASON WHATSOEVER.
        </p>

        <h5>4. Payment Invoices</h5>
        <p>
          Unless otherwise set forth on a Quote, Client agrees to pre-pay Company the price stated on any Quote, prior to the commencement of the applicable Services. Except as set forth herein or on the applicable invoice, each invoice is payable upon receipt. Past due payments shall bear interest at a rate of one percent (1.0%) per month and you shall be liable for all reasonable costs and expenses (including, without limitation, reasonable attorney’s fees) incurred by us in collecting any past due payments. If any amount is not paid when requested or if any invoice is not paid within fifteen (15) days after the date of the invoice, then we reserve the right to discontinue providing services until all amounts past due are paid in full, and/or terminate immediately this Agreement and any Quote. A Quote may require you to deliver an initial retainer, which will be applied to the fees for Services. Upon exhaustion of the initial retainer, and each subsequent retainer, we may ask for an additional retainer in an amount approximately equal to any anticipated additional charges and expenses to be incurred.
        </p>

        <h5>5. Work for Hire</h5>
        <p>
          Upon payment in full to Company of all sums due under a Quote and this Agreement, all “creative elements” provided as part of the Services specified in the Quote will become the property of the Client and all of Company’s title and interest in such creative elements (other than Company’s Pre-Existing Work) will vest in the Client and be deemed a “work made for hire” for copyright purposes, provided, however, unless the Company has agreed in writing with Client to the contrary, that Company is herewith granted a non-exclusive license to utilize any such creative elements for in its business development efforts and marketing materials. Nothing contained in this Agreement shall be deemed to transfer, convey or assign to Client any of Company’s rights in any software, information or other intellectual property which was previously owned by Company prior to the provision of the creative elements and/or services furnished hereunder and/or created independently and without reference to any materials or information of Client (“Pre-Existing Works”); provided, however, that if and to the extent any Pre-Existing Works are or have been provided to Client as part of the creative elements contemplated by this Agreement, in connection with the foregoing grant of the rights to the creative elements, upon payment of all applicable Quote Fees in full, Company grants to Client a royalty-free license in and to such Pre-Existing Works, to use and display same in conjunction with said creative elements.
        </p>

        <h5>6. Milestone Based Services</h5>
        <p>
          A Quote may be for Services provided on a milestone-completion basis. Company shall notify Client by email, text message, telephone call, and/or other electronic means of Company’s completion of a milestone or Service. Client has two (2) calendar days from Company notifying the completion of the milestone or Service (“Acceptance Period”) via email, text message, telephone call, and/or other electronic means to either accept or decline the completed milestone or Service. Client’s failure to notify Company during the Acceptance Period via email signifies Client’s acceptance of the completed milestone or Services. If Client declines the completed milestone or Service within the Acceptance Period, Client must provide details as to what and how the Services do not conform to the Quote.
        </p>
        <h5>7. Change Management Procedures</h5>
        <p>
          It may become necessary to amend a Quote for reasons including, but not limited to, the following: (i) changes to the statement of work and/or specifications for the Services, (ii) changes to the milestone invoice or payment schedule, (iii) changes to the project schedule due to unavailability of resources which are beyond either party’s control, and/or, (iv) technical conditions not previously identified. In the event either party desires to change a Quote, the following procedures shall apply:
        </p>
        <ol>
          <li>The party requesting the change will deliver a “Change Request” to the other party by email. The Change Request will describe the nature of the change, the reason for the change, and the effect the change will have on the scope of work, the project schedule and costs.</li>
          <li>A Change Request may be initiated either by Client or by Company for any changes to the Quote. The parties will evaluate the Change Request and negotiate in good faith the changes to the Services and additional fees, if any, required to implement the Change Request. If both parties agree to implement the Change Request, both parties will sign the Change Request, indicating the acceptance of the changes by the parties.</li>
          <li>Upon acceptance of the Change Request and prepaying any additional Service fees associated therewith, said Change Request will be incorporated into, and made part of, the applicable Quote.</li>
          <li>Company is under no obligation to proceed with the Change Request until such time as the Change Request has been agreed upon in writing by both parties (which may be by email).</li>
        </ol>
        <p>
          Whenever there is a conflict between the terms and conditions set forth in a fully executed Change Request and those set forth in the original Quote, or previous fully executed Change Request, the terms and conditions of the most recent fully executed Change Request shall prevail.
        </p>

        <h5>8. Termination</h5>
        <p>
          Either party has the right to terminate this Agreement or any Quote for any reason, including the ending of Services that are already underway, upon written notice to the other. In the event of Company’s termination of the Agreement or any SOW, Client shall pay Company for all Services performed under the affected SOWs up to the effective date of termination at the agreed upon prices, fees and expense reimbursement rates. In the event of Client’s termination of the Agreement or any SOW by Client, Company will retain all previously paid fees by Client corresponding to the affected SOW, and Client shall pay any remaining fee associated with the Services performed under the affected SOWs up to the effective date of termination at the agreed upon prices, fees and expense reimbursement rates within two (2) calendar days of the effective date of termination.
        </p>

        <h5>9. Revisions</h5>
        <p>
          Unless otherwise stated “Unlimited Revisions” in a Quote/ and or invoice from the company, we offer up to three (3) revisions of each stage of the Services; provided, however, once a revision is accepted by you, any further revisions must be under a Change Request. Client is required to request any revision no later than two (2) calendar days (time being of the essence) after delivery of same by us (and if any revision is requested after such time, we may require you to submit a Change Request for such revisions). Company is not responsible for failing to meet deadlines or timelines if revisions, feedback, / or approval are not provided to us within said 2-day period and/or in case of “Unlimited Revisions” provided in a Quote/Invoice from Company. Notwithstanding the foregoing, this Section does not include revisions to any voiceover acting or voiceover services (“VO”) provided by Company; Company will charge Client for any changes, revisions, digital editing, or modification to the VO requested by Client via a Change Request prior to Company providing the revised VO.
        </p>

        <h5>10. Archived / Inactive Projects</h5>
        <p>
          Your project will be marked as archived/ inactive if you have not responded/ replied to company’s emails/ calls/ text messages about the Services for more than 30 days without any prior notice. Thirty percent (30%) of the total decided project fee will be charged by the company to reactivate your project and resume working on it. A new project’s timeline/ deadline will be shared by the company after projects reactivation.
        </p>

        <h5>11. Publicity</h5>
        <p>
          Unless the Company has agreed in writing with Client to the contrary, you agree that we may use your name, logo and a general description of the Services performed in our business development efforts and materials. The foregoing provision shall survive the termination or expiration of this Agreement. Additionally, from time to time, Company may request a testimonial from you.
        </p>

        <h5>12. Non-Solicitation</h5>
        <p>
          The parties acknowledge that Company’s employees, contractors, sub-contractors and staff members are valuable assets of Company (collectively, “Company Personnel”). Accordingly, unless otherwise agreed to in writing, during the Term and one (1) year thereafter, Client agrees not to solicit, hire, offer to hire, subcontract, or otherwise do business with any Company Personnel (including direct or indirect employment, such as through a temporary staffing firm or on a freelance basis) or otherwise induce any Company Personnel to terminate, and/or otherwise alter the terms of, his or her relationship with Company. As a material inducement to our making introductions or other efforts by us, you agree that you shall not, directly or indirectly, participate, solicit, initiate, or encourage dealings with any Company Personnel to whom you were introduced by us with regard to any Services without the prior written consent and participation of us, and shall not in any way, directly or indirectly, circumvent your obligations to us, or our rights under this Agreement. Further, you agree that should a Company Personnel contact you during the Term or one (1) year thereafter, you shall immediately notify us of such contact and instruct the Company Personnel to contact us directly. Therefore, you agree that Company shall be entitled to injunctive and other equitable relief without bond or other security in the event of such breach in addition to any other rights or remedies, which the Company may possess or be entitled to pursue.
        </p>
        <h5>13. Third-Parties.</h5>
          <ol>
            <li>
              <h5>Third-Party Materials.</h5>
              <p>
                The Services may include or operate in conjunction with materials and information, including documents, data, know-how, ideas, methodologies, specifications, software, content, and technology, in any form or media, in which a third-party owns Intellectual Property Rights (“Third-Party Materials”). We may identify to you Third-Party Materials we included in or that are required for use with the Service. All Third-Party Materials are provided pursuant to the terms and conditions of the applicable third-party license agreement; Client shall comply with all such third-party license agreements. Company does not monitor nor have any control nor input over the Third-Party Materials. ALL THIRD-PARTY MATERIALS ARE PROVIDED “AS IS” AT THE TIME OF THAT THE SERVICES ARE PROVIDED AND ANY REPRESENTATION OR WARRANTY OF OR CONCERNING ANY THIRD-PARTY MATERIALS IS STRICTLY BETWEEN CLIENT AND THE THIRD-PARTY OWNER OR DISTRIBUTOR OF THE THIRD-PARTY MATERIALS. COMPANY HEREBY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, REGARDING THE FUNCTIONALITY, PERFORMANCE, AND COMPLETENESS OF THE THIRD-PARTY MATERIALS. We shall have no liability whatsoever arising from or relating to your use of Third-Party Materials. Any use of Third-Party by you is entirely at your own risk and discretion, and you should ensure that you are familiar with and approve of the terms on which tools are provided by the relevant third-party providers. Any breach by Client of this Section will be deemed a material breach of this Agreement.
              </p>
            </li>
            <li>
              <h5>Third-Party Links.</h5>
              <p>
                Certain content available via the Services may include hyperlinks from third parties. Third-party links on our site may direct you to third-party Websites and/or services that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy, and we do not warrant and will not have any liability or responsibility for any third-party materials or Websites and/or services, or for any other materials, products, or services of third parties. We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third party with whom you connect via the Services or our site. Please review carefully the third party’s policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.
              </p>
            </li>
          </ol>
        </div>

        <div className="section">
          <h5>14. General Provisions</h5>
          <ol>
            <li>
              <h5>Governing Law and Venue.</h5>
              <p>
                For any claim, cause of action, or dispute you have against us that arises out of or relates to these Terms or Services, you agree that it will be resolved exclusively in the state and federal courts located in the county and State of NJ. You also agree to submit to the personal jurisdiction of either of these courts for the purpose of litigating any such claim, and that the laws of the State of NJ will govern these Terms and any claim, without regard to conflict of law provisions.
              </p>
            </li>
            <li>
              <h5>Class Action Waiver.</h5>
              <p>
                Any proceeding to resolve or litigate any dispute in any forum will be conducted solely on an individual basis. Neither you nor we will seek to have any dispute heard as a class action or in any other proceeding in which either party acts or proposes to act in a representative capacity. No proceeding will be combined with another without the prior written consent of all parties to all affected proceedings. You also agree not to participate in claims brought in a private attorney general or representative capacity, or any consolidated claims involving another person’s account, if we are a party to the proceeding. YOU ARE GIVING UP YOUR RIGHT TO PARTICIPATE AS A CLASS REPRESENTATIVE OR CLASS MEMBER ON ANY CLASS CLAIM YOU MAY HAVE AGAINST US INCLUDING ANY RIGHT TO CLASS ARBITRATION OR ANY CONSOLIDATION OF INDIVIDUAL ARBITRATIONS.
              </p>
            </li>
            <li>
              <h5>Survival.</h5>
              <p>
                The respective rights and obligations of the parties to this Agreement shall survive any termination of Company’s services to the extent necessary to the intended preservation of such rights and obligations.
              </p>
            </li>
            <li>
              <h5>Entire Agreement.</h5>
              <p>
                This Agreement is the complete agreement between the parties concerning the subject matter of this Agreement and replaces any prior oral or written communications between the parties, except as agreed between the parties. There are no conditions, understandings, agreements, representations, or warranties expressed or implied, that are not specified herein. This Agreement may only be modified by a written document executed by the parties hereto.
              </p>
            </li>
            <li>
              <h5>Liability.</h5>
              <p>
                In no event shall Company, its respective affiliates, officers, directors, employees, agents or suppliers be liable for any special, incidental, indirect or consequential damages, or lost revenue, lost profits, or lost or damaged data, whether arising in contract, tort (including negligence), or otherwise, even if Client has been informed of the possibility thereof. Company disclaims and Client waives, all liability arising from third party service providers, including website hosting companies, which may be providing services to Client (the foregoing waiver does not preclude Client from pursuing its claims directly with any such third party). All liability of Company, its affiliates, officers, directors, employees, agents and suppliers collectively for claims arising under this Agreement or otherwise howsoever arising shall be limited to the amount paid by Client to Company pursuant to the relevant Quote during the one (1) month preceding the event or circumstances giving rise to such liability. This limitation of liability is cumulative and not per-incident (i.e., the existence of two or more claims will not enlarge this limit). The limitation of liability shall not apply to any breach of any license or indemnity obligation hereunder.
              </p>
            </li>
            <li>
              <h5>Indemnity.</h5>
              <p>
                Customer shall indemnify, defend, and hold Company, and its officers, directors, employees, successors and assigns (“Indemnified Parties”) harmless from and against any and all losses, damages and costs (collectively, “Claims”), incurred by, borne by or asserted against any of the Indemnified Parties to the extent such Claims in any way relate to, arise out of or result any actual or alleged infringement or misappropriation of any patent, copyright, trademark, trade name, trade secret or other proprietary or intellectual property right by any materials or software provided by Customer pursuant to this Agreement. Customer shall also indemnify, defend, and hold Indemnified Parties harmless from and against any and all Claims, incurred by, borne by or asserted against any of the Indemnified Parties to the extent such Claims in any way relate to, arise out of Client’s breach of any of the representations, obligations, or warranties set forth by Client in this Agreement.
              </p>
            </li>
            <li>
              <h5>Disclaimer.</h5>
              <p>
                Except as specified in this section, Company hereby disclaims and Client waives all representations, conditions and warranties (whether express, implied, or statutory), including without limitation, any warranty or condition (a) of merchantability, fitness for a particular purpose, noninfringement, title, satisfactory quality, quiet enjoyment, accuracy, (b) arising from any course of
                dealing, course of performance, or usage in the industry. To the extent an implied warranty cannot be disclaimed, such warranty is limited in duration to the applicable express warranty period. Client’s sole and exclusive remedy for breach of warranty shall be, at Company’s option, re-performance of the services; or termination of this Agreement or the applicable Quote and return of the portion of the service fees paid to Company by Client for such non-conforming services.
              </p>
            </li>
          </ol>
          <h5>8. Assignment</h5>
        <p>
          Neither this Agreement nor any right or obligation under this Agreement shall be assigned by Client without Company’s prior written consent, which will not be unreasonably withheld or delayed. Any attempted assignment shall be void and of no effect.
        </p>

        <h5>9. No Third-Party Beneficiaries</h5>
        <p>
          This Agreement is for the sole benefit of the parties hereto and their authorized successors and assigns. Nothing herein, express or implied, is intended to or shall confer upon any person or entity, other than the parties hereto and their authorized successors and assigns, any legal or equitable right, benefit or remedy of any nature whatsoever under or by reason of this Agreement.
        </p>

        <h5>10. Attorneys’ Fees</h5>
        <p>
          In any suit or proceeding relating to this Agreement the prevailing party will have the right to recover from the other its costs and reasonable fees and expenses of attorneys, accountants, and other professionals incurred in connection with the suit or proceeding, including costs, fees and expenses upon appeal, separately from and in addition to any other amount included in such judgment. This provision is intended to be severable from the other provisions of this Agreement, and shall survive expiration or termination and shall not be merged into any such judgment.
        </p>

        <h5>11. Force Majeure</h5>
        <p>
          Except for the obligation to pay monies due and owing, neither party shall be liable for any delay or failure in performance due to events outside the defaulting party’s reasonable control, including, without limitation, acts of God, earthquakes, labor disputes, industry-wide shortages of supplies, actions of governmental entities, riots, war, terrorism, fire, epidemics, pandemics, or delays or failures of common carriers or other circumstances beyond its reasonable control. The obligations and rights of the defaulting party shall be extended for a period equal to the period during which such event prevented such party’s performance.
        </p>

        <h5>12. Notices</h5>
        <p>
          All notices under this Agreement shall be in writing and shall be sent by email to the respective email addresses provided on the Quote.
        </p>

        <h5>13. Severability</h5>
        <p>
          In the event any provision hereof, or the application thereof in any circumstances, is held to be invalid, illegal or unenforceable by a final or un-appealable order, decree or judgment of any court, the provision in question shall be deemed replaced with a valid and enforceable provision most closely reflecting the intent and purpose of the original provision within the jurisdiction of such court and the Agreement shall otherwise remain in full force and effect in such jurisdiction and in its entirety in other jurisdictions.
        </p>

        <h5>14. No Waiver</h5>
        <p>
          The waiver by either party of any right provided under this Agreement shall not constitute a subsequent or continuing waiver of such right or of any other right under this Agreement.
        </p>

        <h5>15. Mutual Non-Disparagement</h5>
        <p>
          The parties agree that neither it nor any of its respective agents, officers, key employees or directors, will in any way publicly disparage, call into disrepute, defame, slander or otherwise criticize the other party or any of their services, in any manner that would damage the business or reputation or otherwise degrade the other party’s reputation of such other parties, their services in the business or the community or in the entertainment industry.
        </p>

        <h5>16. Employees</h5>
        <p>
          The employees of Company shall throughout the Term of this Agreement be deemed employees of Company for all purposes, and shall not be deemed to be employees or agents of Client for any purpose. Company shall assign the individuals (if any) requested by Client to perform the Services. For the term of this Agreement and for one (1) year thereafter, each party agrees not to employ any employee of the other party.
        </p>

        {/* Repeat this structure for the remaining sections */}
      </div>
    </div>

            
            <Footer />
        </div>
    );
};

export default TermsConditions;
